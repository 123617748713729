import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon, ArrowTopRightOnSquareIcon, HeartIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
function ListItem({ instance, index, displayComments }) {

  const textClasses = "text-gray-100 text-base sm:text-lg font-medium break-words";
  const statClasses = textClasses + " text-right";
  const commentTextClasses = "text-gray-100 text-sm sm:text-base font-medium";
  const rowBackground = index % 2 === 0 ? "bg-list-row-even hover:bg-list-row-even-hover" : "bg-list-row-odd hover:bg-list-row-odd-hover";
  const rowClasses = "flex flex-row " + rowBackground;
  const rowPaddingClass = "p-4";
  const commentContainerClasses = "p-4 ml-5 bg-indigo-800 w-full";

  function formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function severityColour(severity) {
    switch (severity) {
      case "blocked":
        return "text-green-500";
      case "limited":
        return "text-yellow-500";
      case "fedipact":
        return "text-pink-300";
      case "federated":
        return "text-red-500";
      case "not-federated":
        return "text-cyan-500";
      default:
        return "text-gray-100";
    }
  }

  function severityIcon(severity) {
    const classes = "w-5 h-5 inline-block";
    switch (severity) {
      case "blocked":
        return <CheckCircleIcon className={classes} />;
      case "limited":
        return <ExclamationCircleIcon className={classes} />;
      case "fedipact":
        return <HeartIcon className={classes} />;
      case "federated":
        return <XCircleIcon className={classes} />;
      case "not-federated":
        return <InformationCircleIcon className={classes} />;
      default:
        return <InformationCircleIcon className={classes} />;
    }
  }

  function severityTitle(severity) {
    switch (severity) {
      case "blocked":
        return "All communications are blocked";
      case "limited":
        return "Some communications are restricted";
      case "fedipact":
        return "Agreed to block all communications (their blocklist is private)";
      case "federated":
        return "Communication is unrestricted";
      case "not-federated":
        return "No communications - likely blocked";
      default:
        return "Unknown";
    }
  }

  function externalLink(domain) {
    return <a href={"https://" + domain} target="_blank" rel="noreferrer nofollow"><ArrowTopRightOnSquareIcon className="w-4 h-4 inline-block align-text-top text-blue-400" /></a>;
  }

  const comment = instance.comment && displayComments ? (
    <div className={rowClasses + " pt-0 pb-2 pl-2 pr-0"}>
      <div className={commentContainerClasses}>
        <span className={commentTextClasses}>
          <ChatBubbleBottomCenterTextIcon className={"text-white w-5 h-5 inline-block mr-1"} /> 
          <span className="inline-block align-middle">
            <span className="font-bold">Admin: </span> 
            {instance.comment}
          </span>
        </span>
      </div>
    </div>
  ) : null;

  return (
    <li key={instance.id}>
      <div className={rowClasses + " " + rowPaddingClass}>
        <span className={textClasses + " w-2/4 sm:w-2/6"}>{instance.domain} {externalLink(instance.domain)}</span>
        <span className={textClasses + " hidden sm:block sm:w-1/6"}>{instance.software}</span>
        <span className={severityColour(instance.severity) + " text-base sm:text-lg font-medium break-words w-1/4 sm:w-1/6"} title={severityTitle(instance.severity)}>{severityIcon(instance.severity)} <div className="inline-block align-middle">{instance.severity}</div></span>
        <span className={statClasses + " hidden sm:block sm:w-1/6"}>{formatNumber(instance.total_users)}</span>
        <span className={statClasses + " w-1/4 sm:w-1/6"}>{formatNumber(instance.active_month)}</span>
      </div>
      {comment}
    </li>
  )
}

export default ListItem;