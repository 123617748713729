function Footer() {
  const anchor_style = "text-purple-500 hover:text-purple-300";
  return (
    <div className="footer text-center text-xl text-gray-300 py-5">
      <p>Created by <a className={anchor_style} href="https://veganism.social/@nm">Nume MacAroon</a> of <a className={anchor_style} href="https://veganism.social/">Veganism Social</a></p>
      <p>Additional credit to <a className={anchor_style} href="https://vantaa.black/">Vanta</a> for <a className={anchor_style} href="https://fedipact.online/">Fedipact.Online</a></p>
    </div>
  );
}

export default Footer;