import './global.css';

import Footer from './components/footer';
import Instance from './components/instance';


function App({ params }) {
  return (
    <div className="container mx-auto my-10">
      <Instance />
      <Footer />
    </div>
  );
}

export default App;
