// import some icons from heroicons/react
import React from 'react';

// hero icons react v2
import { ArrowUpIcon as Up, ArrowDownIcon as Down } from '@heroicons/react/24/solid';
import { ChatBubbleBottomCenterTextIcon as CommentIcon } from '@heroicons/react/24/solid';

function ListHeader(
    { 
        handleSortByAttribute, 
        sortByAttribute, 
        ascending, 
        handleSetMinimumTotalUsers, 
        minimumTotalUsers, 
        handleSetMinimumActiveMonth, 
        minimumActiveMonth,
        handleSetDisplayComments,
        displayComments,
        softwares,
        filterSoftware,
        handleSetFilterSoftware,
        severities,
        filterSeverity,
        handleSetFilterSeverity,
        handleSetDomainFilter,
    }) {

    let textClasses = "text-white text-lg font-bold ";
    let statClasses = textClasses + " text-right";

    let arrowClasses = "text-white w-4 h-4 inline-block ml-1";

    let defaultListRowClasses = "flex flex-row p-4"

    const minimumOptions = [1, 10, 100, 1000];

    const optionSelect = (options, labelFunction, selected, onChange) => {
        return (
            <select className="mx-1 px-2 py-1 rounded-lg bg-slate-800 text-white text-sm" value={selected} onChange={onChange}>
                {options.map((option) => {
                    return <option value={option}>{labelFunction(option)}</option>
                })}
            </select>
        )
    };

    const minimumTotalUsersButtons = optionSelect(minimumOptions, (o) => { return o + "+" }, minimumTotalUsers, (event) => handleSetMinimumTotalUsers(event.target.value));
    const minimumActiveMonthButtons = optionSelect(minimumOptions, (o) => { return o + "+" }, minimumActiveMonth, (event) => handleSetMinimumActiveMonth(event.target.value));

    const softwaresDropdown = optionSelect(["All", ...softwares], (o) => { return o }, filterSoftware, (event) => handleSetFilterSoftware(event.target.value));
    const severitiesDropdown = optionSelect(["All", ...severities], (o) => { return o }, filterSeverity, (event) => handleSetFilterSeverity(event.target.value));

    const domainFilter = (
        <input
            className="mx-1 px-2 py-1 rounded-lg bg-slate-800 text-white text-sm"
            type="text"
            placeholder="Filter by domain"
            onChange={(event) => handleSetDomainFilter(event.target.value)}
        />
    );

    const commentToggleButton = (selected, onClick) => {
        const colour = selected ? "text-indigo-400" : "text-gray-400";
        return (
            <button className="mx-2" onClick={onClick} title="Toggle Comment Visibility">
                <CommentIcon className={colour + " w-4 h-4 inline-block mr-1"} />
            </button>
        );
    };


    function sortingWrapper(markup, attribute) {
      return (
        <span className="cursor-pointer" onClick={() => handleSortByAttribute(attribute)}>
            {markup}
            {
                sortByAttribute === attribute ? (
                    ascending ? (
                        <Up className={arrowClasses} />
                    ) : (
                        <Down className={arrowClasses} />
                    )
                ) : null
            }
        </span>
      )
    }

    return (
        <>
            <li className={defaultListRowClasses} key="header-options">
                <span className="w-2/4 sm:w-2/6">
                    <span className="hidden sm:block">
                        {domainFilter}
                    </span>
                    <span className="sm:hidden">
                        {softwaresDropdown}
                    </span>
                </span>

                <span className="hidden sm:block sm:w-1/6">
                    <span className="hidden sm:block">
                        {softwaresDropdown}
                    </span>
                </span>

                <span className="w-1/4 sm:w-1/6">
                    {severitiesDropdown}
                </span>

                <span className="w-1/4 sm:w-1/6 text-right">
                    {minimumTotalUsersButtons}
                </span>

                <span className="hidden sm:block sm:w-1/6 text-right">
                    {minimumActiveMonthButtons}
                </span>
            </li>

            <li className={defaultListRowClasses} key="header-sorting">
                <span className="w-2/4 sm:w-2/6">
                    {sortingWrapper(<span title="Synonymous with server or website" className={textClasses}>Instance</span>, "domain")}
                    {commentToggleButton(displayComments, () => handleSetDisplayComments(!displayComments))}
                </span>

                <span className="hidden sm:block sm:w-1/6">
                    {sortingWrapper(<span title="ActivityPub software the instance uses" className={textClasses}>Software</span>, "software")}
                </span>

                <span className="w-1/4 sm:w-1/6">
                    {sortingWrapper(<span className={textClasses}>Status</span>, "severity")}
                </span>

                <span className="w-1/4 sm:w-1/6 text-right">
                    {sortingWrapper(<span title="Total registered users" className={statClasses}>Users</span>, "total_users")}
                </span>

                <span className="hidden sm:block sm:w-1/6 text-right">
                    {sortingWrapper(<span title="Monthly active users" className={statClasses}>Active</span>, "active_month")}
                </span>
            </li>
        </>
    )
  }
  
  export default ListHeader;