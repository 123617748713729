import React, { useEffect, useState } from 'react';
import ListHeader from "./list_header";
import ListItem from "./list_item";

function List({ instances, title, description }) {
  const [ sortByAttribute, setSortByAttribute ] = useState("active_month");
  const [ ascending, setAscending ] = useState(false);
  const [ sortedInstances, setSortedInstances ] = useState([]);

  const [ minimumTotalUsers, setMinimumTotalUsers ] = useState(1);
  const [ minimumActiveMonth, setMinimumActiveMonth ] = useState(1);

  const [ displayComments, setDisplayComments ] = useState(true);

  const [ softwares, setSoftwares ] = useState([]);
  const [ filterSoftware, setFilterSoftware ] = useState(null);

  const [ severities, setSeverities ] = useState([]);
  const [ filterSeverity, setFilterSeverity ] = useState(null);

  const [ limit, setLimit ] = useState(100);

  const [ domainFilter, setDomainFilter ] = useState("");

  useEffect(() => {
    const applyFilters = (instance) => {
      if (filterSoftware && filterSoftware !== 'All' && instance.software !== filterSoftware) {
        return false;
      }
  
      if (filterSeverity && filterSeverity !== 'All' && instance.severity !== filterSeverity) {
        return false;
      }
  
      if (instance.total_users < minimumTotalUsers) {
        return false;
      }
  
      if (instance.active_month < minimumActiveMonth) {
        return false;
      }

      if (domainFilter && !instance.domain.toLowerCase().includes(domainFilter.toLowerCase())) {
        return false;
      }
  
      return true
    }
  
    const sortedArray = [...instances].filter(applyFilters).sort((a, b) => {
      if (ascending) {
        return a[sortByAttribute] > b[sortByAttribute] ? 1 : -1;
      } else {
        return a[sortByAttribute] < b[sortByAttribute] ? 1 : -1;
      }
    });
    setSortedInstances(sortedArray);
  }, [instances, sortByAttribute, ascending, minimumTotalUsers, minimumActiveMonth, filterSoftware, filterSeverity, domainFilter]);

  useEffect(() => {
    const uniqueValues = (attribute) => {
      const values = instances.map((instance) => instance[attribute]);
      return [...new Set(values)];
    }

    setSoftwares(uniqueValues("software"));
    setSeverities(uniqueValues("severity"));
  }, [instances]);

  const handleSortByAttribute = (attribute) => {
    if (attribute === sortByAttribute) {
      setAscending(!ascending);
    } else {
      setSortByAttribute(attribute);
      setAscending(true);
    }
  };

  const handleSetMinimumTotalUsers = (value) => {
    setMinimumTotalUsers(value);
  }

  const handleSetMinimumActiveMonth = (value) => {
    setMinimumActiveMonth(value);
  }

  const handleSetDisplayComments = (value) => {
    setDisplayComments(value);
  }

  const handleSetFilterSoftware = (value) => {
    setFilterSoftware(value);
  }

  const handleSetFilterSeverity = (value) => {
    setFilterSeverity(value);
  }

  const handleSetDomainFilter = (value) => {
    setDomainFilter(value);
  }

  return (
    <div>
      <div className="shadow-lg rounded-lg bg-indigo-900">
        <div className="p-4">
          <h1 className="text-2xl font-bold text-white title">{title}</h1>
          {description}
        </div>
        <ul className="">
          <div className="bg-black bg-opacity-10 border-t border-black border-opacity-5 border-t-2">
            <ListHeader 
              handleSortByAttribute={handleSortByAttribute} 
              sortByAttribute={sortByAttribute} 
              ascending={ascending} 
              handleSetMinimumTotalUsers={handleSetMinimumTotalUsers}
              minimumTotalUsers={minimumTotalUsers}
              handleSetMinimumActiveMonth={handleSetMinimumActiveMonth}
              minimumActiveMonth={minimumActiveMonth}
              handleSetDisplayComments={handleSetDisplayComments}
              displayComments={displayComments}
              softwares={softwares}
              filterSoftware={filterSoftware}
              handleSetFilterSoftware={handleSetFilterSoftware}
              severities={severities}
              filterSeverity={filterSeverity}
              handleSetFilterSeverity={handleSetFilterSeverity}
              handleSetDomainFilter={handleSetDomainFilter}
            />
          </div>
          {sortedInstances.slice(0, limit).map((instance, index) => (
            <li key={instance.id}>
                <ListItem instance={instance} index={index} displayComments={displayComments} />
            </li>
          ))}
        </ul>
      </div>
      <div className="text-center my-4">
        <button className="bg-indigo-900 text-white font-bold text-2xl my-4 py-2 px-4 rounded" onClick={() => setLimit(limit + 100)}>Load more</button>
      </div>
    </div>
  );
}

export default List;