import React, { useState, useEffect } from 'react';
import { 
    getInstanceFederation
} from '../api/instance';

import List from './instance/list';
import Fedipact from './fedipact';

function Instance() {

    const [
        federation, setFederation
    ] = useState([]);

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            getInstanceFederation().then((response) => {
                setFederation(response.data);
            });
            setFirstLoad(false);
        }
    }
    , [firstLoad]);

    return (
        <List 
            title={"#Fedipact - The instances blocking Zuckerberg's Threads.net"}
            description={<Fedipact />}
            instances={federation || []} 
        />
    );
}

export default Instance;