import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

let params = new URLSearchParams(window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App params={params} />
  </React.StrictMode>
);
